<template>
<b-card v-if="users" no-body class="card-company-table">
    <b-card no-body>
        <b-card-header class="pb-50">
            <h5>
                Filtreleme
            </h5>
        </b-card-header>
        <b-card-body>
            <b-row class="mb-2">

                <b-col cols="12" md="6" class="mb-md-0 mb-2">
                    <label>Sınıf/Ders</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedLesson" :options="lessons" class="w-100" :getOptionLabel="option => option.class.name+' / '+option.lesson.name" :reduce="val => val.id" />
                </b-col>

                <b-col cols="12" md="6" class="mb-md-0 mb-2">
                    <label>Alt Sınıf</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="childClassId" :options="childClass" class="w-100" :reduce="val => val.class.id" :getOptionLabel="option => option.class.name" />
                </b-col>

                <b-col cols="12" md="12" class="mb-md-0 mb-2">
                    <b-button variant="success" @click="GetUsers()" style="margin-top:10px;width:100%">FİLTRELE</b-button>
                </b-col>

            </b-row>
            <b-overlay :show="loading" no-wrap>
            </b-overlay>
        </b-card-body>
    </b-card>
    <vue-good-table theme="polar-bear" :rows="users" :columns="fields" :line-numbers="true"  :sort-options="{
    enabled: false,
  }">>

        <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field === 'createdAt'" class="text-nowrap">
                <span class="font-weight-bolder mb-12">{{ props.row.createdAt | moment("DD.MM.YYYY hh:mm")}}</span>
            </div>
            <div v-else-if="props.column.field === 'isActive'" class="text-nowrap">
                <b-badge pill :variant="props.row.isActive ? 'light-success' : 'light-danger'">
                    {{ props.row.isActive ? "AKTİF" : "PASİF" }}
                </b-badge>
            </div>
            <div v-else-if="props.column.field === 'campaignEmail'" class="text-nowrap">
                <b-badge pill :variant="props.row.campaignEmail ? 'light-success' : 'light-danger'">
                    {{ props.row.campaignEmail ? "AKTİF" : "PASİF" }}
                </b-badge>
            </div>
            <div v-else-if="props.column.field === 'campaignSms'" class="text-nowrap">
                <b-badge pill :variant="props.row.campaignSms ? 'light-success' : 'light-danger'">
                    {{ props.row.campaignSms ? "AKTİF" : "PASİF" }}
                </b-badge>
            </div>
            <div v-else-if="props.column.field === 'campaignPhone'" class="text-nowrap">
                <b-badge pill :variant="props.row.campaignPhone ? 'light-success' : 'light-danger'">
                    {{ props.row.campaignPhone ? "AKTİF" : "PASİF" }}
                </b-badge>
            </div>
        </template>
    </vue-good-table>
</b-card>
</template>

<script>
import {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCardText,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BProgress,
    BOverlay
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
export default {
    components: {
        VueGoodTable,
        BFormInput,
        vSelect,
        BForm,
        BFormGroup,
        BFormTextarea,
        BButton,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BCardText,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BProgress,
        BOverlay
    },
    data() {
        return {
            loading: false,
            searchTerm: '',
            fields: [
                { field: 'createdAt', label: 'Kayıt Tarihi' },
                { field: 'isActive', label: 'Durum' },
                {
                    field: 'firstName',
                    label: 'Adı',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'İsme Göre Ara',
                    },
                },
                {
                    field: 'lastName',
                    label: 'Soyadı',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Soyisme Göre Ara',
                    },
                },
                {
                    field: 'email',
                    label: 'E-Posta Adresi',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'E-Posta Adresine Göre Ara',
                    },
                },
                {
                    field: 'gsmNumber',
                    label: 'Telefon Numarası',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Telefona Göre Ara',
                    },
                },
                { field: 'schoolNumber', label: 'Okul Numarası' },
                { field: 'campaignEmail', label: 'E-Posta B.' },
                { field: 'campaignSms', label: 'Sms B.' },
                { field: 'campaignPhone', label: 'Aranarak B.' },
            ],
            users: [],
            lessons: [],
            childClass: [],
            selectedLesson: null,
            childClassId: null,
        }
    },
    created() {
        this.getData();
    },
     mounted() {
        this.$root.$on('AddButtonClick', () => {
          
            this.addUserPanel = true;
        });
    },
    watch: {
        selectedLesson: function (val) {
            this.childClass = [];
            this.childClassId = null;
            if (val != null) {
                var filterData = this.lessons.filter(x => x.id == val)[0];
                this.childClass = filterData.childClass;
            }
        },
    },
    methods: {
        async getData() {
            var lesson = await this.$http.get("Teacher/Lessons");
            this.lessons = lesson.data.data;
        },
        async GetUsers() {
            this.loading = true;
            if (this.childClassId != null) {
                var users = await this.$http.get("Teacher/StudentsChildClass/" + this.childClassId);
                this.users = users.data.data;
            } 
            // else if (this.selectedLesson != null) {
            //     var filterData = this.lessons.filter(x => x.id == this.selectedLesson)[0];
            //     var classId = filterData.class.id;
            //     var users = await this.$http.get("Teacher/Students/" + classId);
            //     this.users = users.data.data;
            // }
            this.loading = false;
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
